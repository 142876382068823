import toast from 'helpers/toast';
import Resizer from "react-image-file-resizer";
import React from 'react'
import { Link } from "react-router-dom";
import moment from "moment";

let user = localStorage.getItem('userSidekick');
user = user ? JSON.parse(user) : null;
const PERMISSIONS = user && user.permissions ? user.permissions : [];

export function checkPermission(permission, action) {
  let current = PERMISSIONS.filter((item) => item.name == permission && typeof item.actions[action] != "undefined" && item.actions[action]);
  return current.length > 0;
}

export function checkPermissions(permissions) {
  let result = false;
  for(let permission of permissions) {
    let permission_actions = permission.actions.filter((item) => { return checkPermission(permission.name, item)})
    if (permission_actions.length > 0) {
      result = true;
      break;
    }
  }
  return result;
}

export function checkExitsRoute (Routes) {
  let location_split = location.pathname.split("/");
  if (location_split.length == 4) {
    let _routes = Routes.filter((item) => item.path.split("/").length == 4);
    let result = false;
    for (let route of _routes) {
      let route_split = route.path.split("/")
      if (route_split[1] == location_split[1] && route_split[3] == location_split[3]) {
        result = true;
        break;
      }
    }
    return result;
  } else {
    let filter = Routes.filter((item) => item.path == location.pathname);
    return filter.length > 0;
  }
}

export function checkImage(imgUrl, imgId, img_undefined) {
  if (imgUrl && imgUrl != "") {
    if (imgUrl.includes("http")) {
      return imgUrl;
    } else {
      let img = new Image();
      imgUrl = process.env.REACT_APP_BASE_URL + imgUrl;
      img.onload = () => {
        let imgEle = document.getElementById(imgId);
        if (imgEle) {
          imgEle.classList.remove("d-none");
          imgEle.src = imgUrl;
        }
      };
      img.onerror = () => {
        let imgEle = document.getElementById(imgId);
        if (imgEle) {
          imgEle.src = img_undefined;
        }
      };
      img.src = imgUrl;
      return img.src
    }
  } else {
    return img_undefined;
  }
}

export function setActiveClassMenu(path, keySplit = 1) {
  let location_split = location.pathname.split("/");
  let _path = path.split("/");
  if (location_split.length > 1 && _path.length > 1) {
    if (location_split[keySplit] == _path[keySplit]) {
      return 'mm-active'
    }
  }
  return '';
}

export function toastError (message_error) {
  if (message_error) {
    toast.error(message_error, "Error");
  } else {
    toast.error("Server Error", "Error");
  }
}

export function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

export function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isInvalidLength(value, minLength = 4, maxLength = 30) {
  return value.length < minLength || value.length > maxLength;
}

export function keypressPrice(e) {
  let key = e.key;
  if((e.target.value.includes(",") || ![','].includes(key)) && isNaN(key)) {
    e.preventDefault();
    return false;
  }
}

export function customUrlParams (loadOptions, url_params = '', sortField = 'id', sortType = 'desc', isSort = true) {
  url_params += `&limit=${isNotEmpty(loadOptions.take) ? loadOptions.take : 10}&offset=${isNotEmpty(loadOptions.skip) ? loadOptions.skip : 0}`;
  if (isSort) {
    if (isNotEmpty(loadOptions.sort) && loadOptions.sort.length > 0) {
      url_params += `&sortField=${loadOptions.sort[0].selector}&sortType=${loadOptions.sort[0].desc ? "desc" : 'asc'}`;
    } else {
      url_params += `&sortField=${sortField ?? "id"}&sortType=${sortType ?? "desc"}`;
    }
  }
  return url_params;
}

export async function resizeImage (file, mWidth = 400, mHeight = 400, image_extension = 'JPEG', result_type = "file") {
  return await new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, mWidth, mHeight, image_extension, 100, 0,
      (uri) => {
        resolve(uri);
      },
      result_type, 200, 200
    );
  });
}

export function capitalizeFirstLetter (e) {
  return e.charAt(0).toUpperCase() + e.slice(1);
}

const appointmentStatuses = [
  {value: '', label: "All"},
  {value: 'cancel', label: "Canceled"},
  {value: 'completed', label: "Completed"},
  {value: 'expired', label: "InCompleted"},
  {value: 'rescheduled', label: "Rescheduled"},
  {value: 'pending', label: "Reserved"}
];

export function getAppointmentStatuses () {
  return appointmentStatuses;
}

export function getAppointmentStatusLabel(status) {
  let _status = appointmentStatuses.find(item => { return item.value == status});
  if (_status) {
    return _status.label;
  }
  return status
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export function formatAmountUs(num) {
  num = Number(num);
  let numDigits = num % 1 == 0 ? 0 : 2;
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: numDigits, maximumFractionDigits: numDigits }).format(num)
}

export function formatDurationTime (durationMinutes) {
  let duration = '';
  if (durationMinutes) {
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    if (hours) {
      duration += hours + 'hr';
    }
    if (minutes) {
      duration += minutes + 'min';
    }
  }
  return duration
}

export const dataDashboardDefault = {
  users: {
    have_least_one_session: {
      value: 0,
      description: "Number of users who have engaged in at least one session"
    },
    total_users: {
      value: 0,
      description: "Total users"
    }
  },
  sidekicks: {
    total_sidekicks: {
      requested: {
        value: 0,
        description: "Number of individuals who have applied to become sidekicks"
      },
      approved: {
        value: 0,
        description: "Count of approved sidekicks who have completed the onboarding process"
      },
      rejected: {
        value: 0,
        description: "Number of sidekick applications not meeting platform criteria"
      },
      pending: {
        value: 0,
        description: "Number of sidekicks undergoing screening or evaluation process"
      }
    },
    have_least_one_session: {
      value: 0,
      description: "Number of sidekicks who have conducted at least one session"
    },
    not_available: {
      value: 0,
      description: "Count of sidekicks with no open sessions available"
    },
    fully_registered: {
      value: 0,
      description: "Number of sidekicks without a registered Stripe account or assigned category"
    }
  },
  sessions: {
    total_sessions: {
      completed: {
        value: 0,
        description: "Overall number of sessions conducted on the platform"
      },
      incomplete_by_sidekick: {
        value: 0,
        description: "Sessions incomplete due to sidekick-related reasons"
      },
      incomplete_by_user: {
        value: 0,
        description: "Sessions incomplete due to user-related reasons"
      },
      incomplete_by_both: {
        value: 0,
        description: "Sessions incomplete due to reasons involving both sidekick and use"
      },
      reserved: {
        value: 0,
        description: "Number of reserved but not yet conducted sessions"
      }
    }
  },
  revenue: {
    total_revenue: {
      value: 0,
      description: "Overall revenue generated on the platform"
    },
    avg_revenue_sidekick: {
      value: 0,
      description: "Average Revenue Per Sidekick (Total Revenue / Total sidekicks)"
    },
    avg_revenue_sidekick_have_least_one_session: {
      value: 0,
      description: "Average Revenue Per Paid Sidekick (Total Revenue / The number of sidekicks who offered session at least one time)"
    },
    avg_revenue_user: {
      value: 0,
      description: "Average Pay Per User (Total Revenue / Total users)"
    },
    avg_revenue_user_have_least_one_session: {
      value: 0,
      description: "Average Pay Per Paid User (Total Revenue / The number of users who paid for the session at least one time)"
    }
  },
  ambassadors: {
    total: {
      value: 0,
      description: "Number of ambassadors"
    },
    sidekicks_by_ambassador: {
      value: 0,
      description: "Number of sidekicks associated with each Ambassador"
    },
    revenue_sidekicks_by_ambassador: {
      value: 0,
      description: "Revenue generated by sidekicks affiliated with each Ambassador"
    }
  }
}

export function customLinkToStripe (e) {
  let data = e.data;
  return <>
    {
      data.payment_intent_id && data.link_to_stripe ?
      <a href={data.link_to_stripe} target="_blank" rel="noreferrer" title="Go to Stripe">
        { data.payment_intent_id }
      </a>
      : ''
    }
  </>
}

export function customLinkToTransfer (e) {
  let data = e.data;
  return <>
    {
      data.transfer_id && data.link_to_transfer ?
      <a href={data.link_to_transfer} target="_blank" rel="noreferrer" title="Go to Transfer">
        { data.transfer_id }
      </a>
      : ''
    }
  </>
}

export function formatAmount (e) {
  let data = e.data
  return (
    <span className="text-right">
      { data && data.amount ? formatAmountUs(data.amount) : ''}
    </span>
  )
}

export function formatAmountHold (e) {
  let data = e.data;
  return (
    <div className="text-right">
      { data.amount_hold ? formatAmountUs(data.amount_hold) : ''}
    </div>
  )
}

export function formatTotalCharge (e) {
  let data = e.data;
  return (
    <div className="text-right">
      { data.total_charge ? formatAmountUs(data.total_charge) : ''}
    </div>
  )
}

export function formatStripeFee (e) {
  let data = e.data;
  return (
    <div className="text-right">
      { data.stripe_fee ? formatAmountUs(data.stripe_fee) : ''}
    </div>
  )
}

export function formatTransfer (e) {
  let data = e.data;
  return (
    <div className="text-right">
      { data.transfer ? formatAmountUs(data.transfer) : 0}
    </div>
  )
}

export function formatRefund (e) {
  let data = e.data;
  return (
    <div className="text-right">
      { data && data.refund ? formatAmountUs(data.refund) : 0}
    </div>
  )
}

export function customExpert (e) {
  let data = e.data
  return (
    checkPermission("expert", "read") && data.expert && data.expert.id ? 
      <>
        <Link to={`/sidekicks/${data.expert.id}/detail`}>
          {data.expert.name}
        </Link>
      </> :
      <span> {data?.expert?.name} </span>
  )
}

export function customExpertCategories (e) {
  let data = e.data;
  return (
    <>
      { data.expert && data.expert.categories && data.expert.categories.length > 0 ?
        data.expert.categories.map(item => { return item.name }).join(", ") : ''
      }
    </>
  )
}

export function customCustomer (e) {
  let data = e.data
  return (
    checkPermission("user", "read") && data.customer && data.customer.user_id ? 
      <>
        <Link to={`/users/${data.customer.user_id}/detail`}>
          {data.customer.name}
        </Link>
      </> :
      <span> {data?.customer?.name} </span>
  )
}

export function formatStartTime (e) {
  let data = e.data
  return (
    <span>
      { moment(data.start_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
    </span>
  )
}

export function formatEndTime (e) {
  let data = e.data
  return (
    <span>
      { moment(data.end_time).format(process.env.REACT_APP_DATE_TIME_FORMAT_MOMENT)}
    </span>
  )
}

export function formatReason (reason) {
  if (reason) {
    let result = reason.split('_');
    if (result.length) {
      result[0] = capitalizeFirstLetter(result[0])
      result = result.join(" ");
      return <span>{result}</span>
    }
  }
  return '';
}

export function customTransactionStatus (e) {
  const failedStatuses = ["transfer_failed", "refund_failed"];
  if (e.data && e.data.transaction_status) {
    return (
      <span className={failedStatuses.includes(e.data.transaction_status) ? "text-danger" : '' }>
        { capitalizeFirstLetter(e.data.transaction_status).replaceAll("_", " ") }
      </span>
    )
  }
  return '';
}