import React, { Component } from "react";
import {
  Container,
  Row, Col,
  Button,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
 } from "reactstrap";
import { Link } from "react-router-dom";
import expertFeaturedService from "../../services/expert-featured";
import MetaTags from "react-meta-tags";
import ModalConfirm from "components/modals/modalConfirm"
import { checkPermission, customUrlParams, capitalizeFirstLetter } from 'helpers/supportFunction';
import { checkImage } from 'helpers/checkImage';
import imageExpert from "../../assets/images/image-expert.jpg";
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging, RowDragging, Sorting, Scrolling } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';

class Expert extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experts: [],
      isDetail: false,
      currentExpertFeatured: null,
      isOpenConfirm: false,
      search: '',
      waiting: false,
      dxStore: [],
      onChangeOrder: false,
      open_modal_confirm: false
    }
    this.onReorder = this.onReorder.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("expert-featured", "read")) {
      toast.warning("You do not have permission to manage banner featured", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData();
    }
  }

  async loadData() {
    let url_params = ""
    let loadOptions = { take: -1, skip: 0, };
    if (this.state.search) {
      url_params += `&search=${this.state.search}`;
    }
    let params = customUrlParams(loadOptions, url_params, 'order', 'desc', false)
    var response = await expertFeaturedService.getAll(params);
    let data = [];
    if(response.success) {
      data = response.data.map((item, index) => { item.order = item.order ? item.order : index + 1; return item});
    }
    this.setState({ dxStore: data});
  }

  onReorder(e) {
    e.promise = this.processReorder(e);
  }

  async processReorder(e) {
    let dxStore = JSON.parse(JSON.stringify(this.state.dxStore));
    let oldOrder = dxStore.map(item => { return item.order });
    dxStore = this.array_move(dxStore, e.fromIndex, e.toIndex);
    
    dxStore = dxStore.map((item, index) => {
      item.order = oldOrder[index];
      return item;
    })
    this.setState({
      dxStore: dxStore,
      onChangeOrder: true
    })
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

  customImage = (e) => {
    let rowData = e.data;
    return (
      <div
        className="text-center"
      > 
        <a 
          href="#"
          title="Show Image"
          alt="Show Image"
          onClick={() => {
            this.setState({
              isDetail: true,
              currentExpertFeatured: rowData,
            })
          }}
        >
          <img
            id={`image_expert_featured_${rowData.id}`}
            src={
              rowData.image ? 
              checkImage(rowData.image, `image_expert_featured_${rowData.id}`, imageExpert)
              : imageExpert
            }
            className="image-expert-featured-list"
            title={`Featured Sidekick ` + rowData.expert_name }
          ></img>
        </a>
      </div>
    )
  }

  customName = (e) => {
    let expert = e.data;
    return (
      checkPermission("expert", "read") ? 
        <>
          <Link to={`/sidekicks/${expert.expert_id}/detail`}>
            { expert.expert_name }
          </Link>
        </> :
        <span> {expert.expert_name} </span>
    )
  }

  customStatus = (e) => {
    let expert = e.data;
    return (
      <span>
      { expert.status ? capitalizeFirstLetter(expert.status) : ''}
      </span>
    )
  }

  linkEdit = (e) => {
    let expert = e.data;
    return (
      <>
      { checkPermission("expert-featured", "update") ?
        <Link to={`/sidekicks-featured/${expert.id}/edit`}
          className="btn btn-primary mx-1 btn-custom-width"
        >
          Update
        </Link>
        : ''
      }

      { checkPermission("expert-featured", "delete") ?
          <Button
            className="mx-1 btn-custom-width"
            color="danger"
            onClick={() => {
              this.setState({
                isOpenConfirm: true,
                currentExpertFeatured: expert,
              })
            }}
          >
            Delete
          </Button>
        : <></>
      }
      </>
    )
  }

  onClose = () => {
    this.setState({
      isDetail: false,
      isOpenConfirm: false,
      currentExpertFeatured: null,
    })
  }

  onActionConfirm = async () => {
    this.setState({ waiting: true });
    let response = await expertFeaturedService.deleteExpertFeatured({
      expert_featured_id: this.state.currentExpertFeatured.id,
    })
    if (response && response.success) {
      toast.success("Delete Banner Featured Success", "Delete");
      this.onClose();
      this.loadData();
    }
    this.setState({ waiting: false });
  }

  handleInputSearch = e => {
    if (this.state.onChangeOrder) {
      this.setState({open_modal_confirm: true});
    } else {
      this.setState({ search: e }, () => 
        this.loadData()
      );
    }
  }

  saveReorder = async () => {
    this.setState({ waiting: true });
    let expert_features = [];
    this.state.dxStore.map(item => {
      expert_features.push({id: item.id, order: item.order});
    });
    let response = await expertFeaturedService.updateReorder({expert_features: expert_features})
    if (response && response.success) {
      toast.success("Update Reorder Success", "Update Success");
    }
  }

  onCloseModalConfirm = () => {
    this.setState({open_modal_confirm: false, onChangeOrder: false, waiting: false });
    this.loadData();
  }

  onActionConfirmChangeOrder = async () => {
    await this.saveReorder();
    this.onCloseModalConfirm();
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Banner Featured | Sidekick</title>
          </MetaTags>
          <Container fluid className={`${this.state.waiting ? 'waitLoadingData' : ''}` }>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center pb-2">
                    <h4 className="mb-0 text-primary">Manage Banner Featured</h4>
                  </Col>
                  <Col xl="4" md="6" className="pt-2 pb-2">
                    <Input
                      type="search"
                      id="search"
                      className="form-control"
                      placeholder="Search..."
                      value={this.state.search}
                      onClick={e => {
                        this.handleInputSearch('')
                      }}
                      onChange={e => {
                        this.handleInputSearch(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xl="8" md="6" className="text-right pt-2 pb-2">
                    {checkPermission("expert-featured", "update") ?
                      <Button
                        color="success"
                        type="button"
                        style={{width: 120, marginRight: 5}}
                        disabled={!this.state.onChangeOrder}
                        onClick={() => {
                          this.onActionConfirmChangeOrder()
                        }}
                      >
                        Save Reorder
                      </Button>
                    : ''}
                    {checkPermission("expert-featured", "create") ?
                      <Link to={`/sidekicks-featured/create`}
                        className="btn btn-primary btn-custom-width"
                      >
                        Create
                      </Link>
                    : ''}
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col xl="12">
                    <DataGrid
                      dataSource={this.state.dxStore}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                      rowAlternationEnabled={true}
                      style={{maxHeight: 700}}
                    >
                    <RowDragging
                      allowReordering={!this.state.search}
                      onReorder={this.onReorder}
                      showDragIcons={true}
                    />
                      <Scrolling mode="virtual" />
                      <Sorting mode="none" />
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80}></Column>
                      <Column dataField="expert_name" name="expert_name" caption="Sidekick" cellRender={this.customName}></Column>
                      <Column dataField="email" name="email" caption="Sidekick Email"></Column>
                      <Column dataField="category_name" name="category_name" caption="Category"></Column>
                      <Column dataField="status" name="status" caption="Status" alignment={'center'} cellRender={this.customStatus} ></Column>
                      <Column dataField="Image" name="Image" caption="Image" cellRender={this.customImage} ></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'center'} width={240}></Column>

                      <Paging defaultPageSize={10} />
                      <Pager
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <ModalConfirm
              isOpen={this.state.isOpenConfirm}
              title={'Confirmation Delete'}
              message={<><span> This action can not undo. </span> <br></br> <span>Are you sure you want to perform this action?</span></>}
              textButton={'Delete'}
              colorButton={"danger"}
              onCloseModal={() => {
                this.onClose();
              }}
              onActionConfirm={ async () => {
                await this.onActionConfirm()
              }}
            />
            <Modal
              isOpen={this.state.isDetail}
              role="dialog"
              autoFocus={true}
              size="lg"
              centered
              data-toggle="modal"
              toggle={() => {
                this.onClose()
              }}
            >
              <div>
                <ModalHeader
                  className="border-bottom-0"
                  toggle={() => {
                    this.onClose()
                  }}
                ></ModalHeader>
              </div>
              <div className="modal-body pt-0">
                <div className="mb-2">
                  <Row className=" justify-content-center">
                    <Col xl="12">
                      {this.state.currentExpertFeatured && this.state.currentExpertFeatured.id ?
                        <>
                          <Row>
                            <Col xl="12" style={{textAlign: 'left'}} className="mb-4">
                              <div
                                className="text-center"
                              >
                                <img
                                  id={`image_expert_featured_${this.state.currentExpertFeatured.id}`}
                                  src={
                                    this.state.currentExpertFeatured.image ? 
                                    checkImage(this.state.currentExpertFeatured.image, `image_expert_featured_${this.state.currentExpertFeatured.id}`, imageExpert)
                                    : imageExpert
                                  }
                                  className="image-expert-featured-detail"
                                  title={`Featured Sidekick ` + this.state.currentExpertFeatured.expert_name }
                                ></img>
                              </div>
                            </Col>
                          </Row>
                        </>
                        : ''
                      }
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal>

            <ModalConfirm
              isOpen={this.state.open_modal_confirm}
              title={'Confirmation Change'}
              message={'Do you want to save the previous changes?'}
              textButton={'Submit'}
              colorButton={'primary'}
              showCancel={true}
              textCancel={'Cancel'}
              onCloseModal={() => {
                this.onCloseModalConfirm();
              }}
              onActionConfirm={ async () => {
                await this.onActionConfirmChangeOrder()
              }}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Expert
