import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  FormFeedback,
  Label,
  Card,
  CardBody
} from "reactstrap"
import { Link } from "react-router-dom";
import settingService from "../../services/setting"
import MetaTags from "react-meta-tags"
import { checkPermission } from 'helpers/supportFunction';
import toast from 'helpers/toast';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';

class Setting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      settings_text: [],
      settings: [],
      isEdit: false,
      isSubmit: false,
      currentSetting: null,
      max_commission_rate: parseFloat(process.env.REACT_APP_MAX_COMMISSION_RATE ?? 20)
    }
  }

  componentDidMount() {
    if (localStorage.getItem('userSidekick') && !checkPermission("setting", "read")) {
      toast.warning("You do not have permission to manage settings", "Invalid Permission");
      setTimeout(() => {
        window.location = '/page-404';
      }, 3000);
    } else {
      this.loadData()
    }
  }
  onClose = () => {
    this.setState({
      isEdit: false,
      isSubmit: false,
      currentSetting: null,
    })
  }

  loadData = async () => {
    let url_params = `&sortField=id&sortType=desc`;
    let response = await settingService.getAll(url_params);
    let settings_text = [];
    let settings = [];
    response.data.forEach(setting => {
      if (setting.type == 'decimal') {
        settings.push(setting);
      } else {
        settings_text.push(setting);
      }
    });
    this.setState({
      settings: settings,
      settings_text: settings_text
    })
  }

  handleInputText = (type, value) => {
    let currentSetting = { ...this.state.currentSetting }
    currentSetting[[type]] = value
    this.setState({ currentSetting: currentSetting })
  }

  onClickUpdate = async setting => {
    this.setState({
      isSubmit: true
    });
    if (this.state.currentSetting.value && this.state.currentSetting.key == 'commission_rate' && (this.state.currentSetting.value > 20 || this.state.currentSetting.value < 0)) {
      toast.warning('Commission rate must be greater than 0 and less than or equal to 20', 'Data Invalid')
    } else {
      if (this.state.currentSetting && this.state.currentSetting.value) {
        let response = await settingService.updateSetting({
          key: setting.key,
          value: setting.value
        })
        if (response.success) {
          toast.success("Update setting success");
          this.onClose();
          this.loadData();
        }
      }
    }
  }

  customId = (e) => {
    let setting = e.data;
    return (
      <Link to="#"
        onClick={() => this.linkUpdate(setting)}
      >
        {setting.id}
      </Link>
    )
  }

  customId2 = (e) => {
    let setting = e.data;
    return (
      <Link to="#"
        onClick={() => this.linkUpdate(setting)}
      >
        {setting.id}
      </Link>
    )
  }

  linkUpdate = setting => {
    if (setting.value !== "" && setting.key !== "") {
      setting.description = setting.description ?? '';
      this.setState({
        isEdit: true,
        currentSetting: setting,
      })
    }
  }

  linkEdit = (e) => {
    let setting = e.data;
    return (
      <>
       { checkPermission("setting", "update") ?
          <Button
            className="mb-2 mx-1 btn-custom-width"
            color="primary"
            onClick={() => this.linkUpdate(setting)}
          >
            Update
          </Button>
          : ''
        }

        { checkPermission("setting", "read") ?
          <>
            <br></br>
            <Link to={`/settings/commission-rate-logs`}>
              <Button className="mx-1 btn-custom-width" color="secondary">
                View Logs
              </Button>
            </Link>
          </>
          : ''
        }
      </>
    )
  }

  linkEdit2 = (e) => {
    let setting = e.data;
    return (
      <>
       { checkPermission("setting", "update") ?
          <Button
            className="mx-1 btn-custom-width"
            color="primary"
            onClick={() => this.linkUpdate(setting)}
          >
            Update
          </Button>
          : ''
        }
      </>
    )
  }
  handleTableChange = (type) => {
    setTimeout(() => {
      this.setState(() => ({
        page,
        sizePerPage,
      }))
      this.loadData()
    }, 500)
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Settings | Sidekick</title>
          </MetaTags>
          <Container fluid>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12" className="d-flex align-items-center mb-3">
                    <h4 className="mb-0 me-3 text-primary">Manage Settings</h4>
                  </Col>
                </Row>
                <Row className="">
                  <Col xl="12">
                    <DataGrid
                      id="settingDecimal"
                      dataSource={this.state.settings}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={false}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                      allowSorting={false}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80} cellRender={this.customId} allowSorting={false}></Column>
                      <Column dataField="key" name="key" caption="Key" allowSorting={false}></Column>
                      <Column dataField="name" name="name" caption="Name" allowSorting={false}></Column>
                      <Column dataField="type" name="type" caption="Type" allowSorting={false}></Column>
                      <Column dataField="value" name="value" caption="Value" allowSorting={false}></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions" allowFixing={true} allowResizing={false} allowSorting={false} cellRender={this.linkEdit} alignment={'left'} width={125}></Column>
                    </DataGrid>
                    <hr className="mt-4 mb-4"></hr>
                    <DataGrid
                      id="settingText"
                      dataSource={this.state.settings_text}
                      showBorders={true}
                      remoteOperations={true}
                      columnAutoWidth={false}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      hoverStateEnabled={true}
                      allowSorting={false}
                    >
                      <Column dataField="id" name="id" caption="ID" alignment={'center'} width={80} cellRender={this.customId}></Column>
                      <Column dataField="key" name="key" caption="Key"></Column>
                      <Column dataField="name" name="name" caption="Name"></Column>
                      <Column dataField="type" name="type" caption="Type"></Column>
                      <Column dataField="value" name="value" caption="Value"></Column>
                      <Column dataField="Actions" name="Actions" caption="Actions"  allowFixing={true} allowResizing={false} allowSorting={false} cellRender={this.linkEdit2} alignment={'center'} width={125}></Column>
                    </DataGrid>
                    <Modal
                      isOpen={this.state.isEdit}
                      role="dialog"
                      autoFocus={true}
                      centered
                      data-toggle="modal"
                      backdrop={'static'}
                      toggle={() => {
                        this.onClose()
                      }}
                    >
                      <div>
                        <ModalHeader
                          className="border-bottom-0"
                          toggle={() => {
                            this.onClose()
                          }}
                        ></ModalHeader>
                      </div>
                      <div className="modal-body">
                        <div className=" mb-4">
                          <Row className=" justify-content-center">
                            <Col xl="12">
                              <h4 className="text-primary text-center mb-3">
                              Update Setting { this.state.currentSetting ? this.state.currentSetting.name : '' }
                              </h4>
                              {this.state.currentSetting && this.state.currentSetting.key ?
                                <>
                                  <Row>
                                    <Col xl="12" style={{textAlign: 'left'}} className="mb-3">
                                      <Label for="value" style={{"paddingLeft": "0"}}>Value</Label>
                                      <div className="input-group rounded bg-light">
                                        <Input
                                          type="text"
                                          id="value"
                                          name="value"
                                          className="form-control bg-transparent border-0"
                                          placeholder="value"
                                          value={this.state.currentSetting.value}
                                          onChange={e => {
                                            this.handleInputText("value", e.target.value)
                                          }}
                                          invalid={
                                            this.state.isSubmit && !this.state.currentSetting.value
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      {
                                        this.state.isSubmit && !this.state.currentSetting.value ? 
                                          <FormFeedback type="invalid" className="invalid-inline">
                                            Value is required
                                          </FormFeedback>
                                        : ''
                                      }
                                      {
                                        this.state.isSubmit && this.state.currentSetting.value 
                                        && this.state.currentSetting.key == 'commission_rate' 
                                        && (parseFloat(this.state.currentSetting.value) > this.state.max_commission_rate || parseFloat(this.state.currentSetting.value) < 0) ? 
                                          <FormFeedback type="invalid" className="invalid-inline">
                                            Commission rate must be greater than 0 and less than or equal to {this.state.max_commission_rate}
                                          </FormFeedback>
                                        : ''
                                      }
                                    </Col>
                                  </Row>
                                  <Row>
                                    <div>
                                      <Button
                                        className="mt-3 mx-auto d-block"
                                        color="primary"
                                        type="button"
                                        id="button-update"
                                        onClick={() => {
                                          this.onClickUpdate(this.state.currentSetting)
                                        }}
                                      >
                                        Update
                                      </Button>
                                    </div>
                                  </Row>
                                </>
                                : ''
                              }
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Modal>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Setting
