import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom";
import { Routes } from "routes"
import { checkPermissions, checkExitsRoute } from 'helpers/supportFunction';

function permissions(route) {
  if (route.permissions) {
    return checkPermissions(route.permissions);
  }
  return true;
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const route = rest.route;
      if (isAuthProtected && (!localStorage.getItem("authUser") || !localStorage.getItem("userSidekick"))) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("userSidekick");
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      } else if (!checkExitsRoute(Routes) || (isAuthProtected && route.permissions && !permissions(route.permissions))) {
        return <Redirect
          push to={{ pathname: "/page-404", state: { from: props.location } }}
        />
      } else if (
        !isAuthProtected && localStorage.getItem("authUser") && location.pathname != '/page-404' && location.pathname != '/logout' 
      ) {
        return (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  isPublicRoute: PropTypes.bool
}

export default Authmiddleware
